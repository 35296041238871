import { useEffect, useState } from "react";
import axios from "axios";

import { TextField, Button, Icon, AppBar, Container, Typography, Toolbar } from "@mui/material";
import Wrapper from "../WrapperDesktop/Wrapper";
import styles from "./HelpPage.module.css";
import MailIcon from "../../assets/HelpPageIcons/mail-icon.svg";
import CallIcon from "../../assets/HelpPageIcons/call-icon.svg";
import MapIcon from "../../assets/HelpPageIcons/map-icon.svg";
import InstagramIcon from "../../assets/HelpPageIcons/instagram-icon.svg"
import FacebookIcon from "../../assets/HelpPageIcons/facebook-icon.svg"
import LinkedinIcon from "../../assets/HelpPageIcons/linkedin-icon.svg"
import { useNavigate } from "react-router-dom";
import UnAuthNav from "../Common/NavBars/UnAuthNav";

const HelpPage = () => {
    const token = localStorage.getItem("token");
    const navigate = useNavigate()
    const [userQuery, setUserQuery] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    });
    // const [isValidToken, setIsValidToken] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        if (token) {
          setIsLoggedIn(true);
        }
      }, [token]);

    const handleClickAbout = () => {
    navigate('/about-us')
    }

    const handleClickHelp = () => {
        navigate('/help')
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/contact-us`,
                {
                    name: userQuery.name,
                    email: userQuery.email,
                    phone: userQuery.phone,
                    message: userQuery.message,
                }
            );

            console.log(response.data.message);

            setUserQuery({
                name: "",
                email: "",
                phone: "",
                message: "",
            });

            setSubmissionStatus("success");
        } catch (error) {
            console.error("Error sending message:", error.response.data.message);
            setSubmissionStatus("error");
        }
    };

    const onChangeName = (event) => {
        setUserQuery((prevData) => {
            return { ...prevData, name: event.target.value };
        });
    };

    const onChangeEmail = (event) => {
        setUserQuery((prevData) => {
            return { ...prevData, email: event.target.value };
        });
    };

    const onChangePhone = (event) => {
        setUserQuery((prevData) => {
            return { ...prevData, phone: event.target.value };
        });
    };

    const onChangeMessage = (event) => {
        setUserQuery((prevData) => {
            return { ...prevData, message: event.target.value };
        });
    };

    return (
        <>
            {isLoggedIn ? (
               <Wrapper />

            ) : (
                <UnAuthNav />
            )}
            <div className={styles.pageHeader}>
                Contact Us
            </div>
            <div className={styles.pageContainer}>
                <form className={styles.leftContainer}>
                    <TextField
                        type="text"
                        label="Name"
                        variant="outlined"
                        value={userQuery.name}
                        onChange={onChangeName}
                        className={styles.customTextfield}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'transparent',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'transparent',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'transparent',
                                },
                            },
                        }}
                    />
                    <TextField
                        type="email"
                        label="Email"
                        variant="outlined"
                        className={styles.customTextfield}
                        value={userQuery.email}
                        onChange={onChangeEmail}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'transparent',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'transparent',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'transparent',
                                },
                            },
                        }}
                    />
                    <TextField
                        type="tel"
                        label="Phone"
                        variant="outlined"
                        className={styles.customTextfield}
                        value={userQuery.phone}
                        onChange={onChangePhone}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'transparent',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'transparent',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'transparent',
                                },
                            },
                        }}
                    />
                    <TextField
                        type="text"
                        placeholder="Message"
                        className={styles.customTextfield}
                        style={{ height: '5.513vw', marginBottom: "0.512vw" }}
                        value={userQuery.message}
                        onChange={onChangeMessage}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'transparent',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'transparent',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'transparent',
                                },
                            },
                        }}
                    />
                    <Button
                        style={{
                            backgroundColor: "#313C63",
                            color: "white",
                            width: "32.051vw",
                            textTransform: "capitalize",
                            height: "2.8125rem",
                            fontSize: "1.026vw",
                            borderRadius: "0.513vw"
                        }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                    {submissionStatus === "success" && (
                        <p style={{ color: "green", textAlign: "center" }}>
                            Message sent successfully!
                        </p>
                    )}
                    {submissionStatus === "error" && (
                        <p style={{ color: "red", textAlign: "center" }}>
                            Error sending message. Please try again.
                        </p>
                    )}
                </form>
                <div className={styles.rightContainer}>
                    <div className={styles.cardContainer}>
                        <div className={styles.cardLeft}>
                            <img src={MailIcon} alt="mail" style={{ height: "0.769vw", width: "0.962vw" }} />
                            <div className={styles.cardText}>Email</div>
                        </div>
                        <div className={styles.cardText}>
                            info@smartsa.in
                        </div>
                    </div>
                    <div className={styles.cardContainer}>
                        <div className={styles.cardLeft}>
                            <img src={CallIcon} alt="call" style={{ height: "0.769vw", width: "0.808vw" }} />
                            <div className={styles.cardText}>Call</div>
                        </div>
                        <div className={styles.cardText}>
                            +91 9503808388 | +91 8099844409
                        </div>
                    </div>
                    <div className={styles.addressCard}>
                        <div className={styles.cardLeft}>
                            <img src={MapIcon} alt="map" style={{ height: "0.769vw", width: "0.625vw" }} />
                            <div className={styles.cardText}>Address</div>
                        </div>
                        <div className={styles.addressText}>
                            3rd Floor, B-Wing, CASP Bhawan, near Uttam Suppermarket, Baner Pashan Link Road, Pashan, Pune, Maharashtra-411021
                        </div>
                    </div>
                    <div className={styles.messageContainer}>
                        <div className={styles.bottomText}>Send us a message or connect on..</div>
                        <div  className={styles.iconContainer}>
                            <a href="https://www.instagram.com/smartsa.in?igsh=Y3gxbzY0c2QyZ3d2" target="_blank">
                                <img src={InstagramIcon} className={styles.iconStyle} />
                            </a>
                            <a href="https://www.linkedin.com/company/smartsa-india/" target="_blank">
                                <img src={LinkedinIcon} className={styles.iconStyle} />
                            </a>
                            <a href="https://m.facebook.com/profile.php/?id=100086846304635&name=xhp_nt__fb__action__open_user" target="_blank">
                                <img src={FacebookIcon} className={styles.iconStyle} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HelpPage;