import { AppBar, Container, Toolbar, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import styles from './UnAuthNav.module.css'
import SmartsaLogo from '../../../assets/DashboardIcons/Logo-Desktop.svg'

const UnAuthNav = () => {
    const navigate = useNavigate()

    const handleClickAbout = () => {
        navigate('/about-us')
    }

    const handleClickHelp = () => {
        navigate('/help')
    }

    return (
        <>
            <AppBar position="static" sx={{ background: '#EAF1F7', borderRadius: '8px' }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between', }}>
                        <a href="/">
                            <img
                                src={SmartsaLogo}
                                alt="Company Logo"
                                className={styles.logoStyle}
                            />
                        </a>
                        <div className={styles.headerLinks}>
                            <Typography onClick={handleClickAbout}>About Us</Typography>
                            <Typography onClick={handleClickHelp}>Help</Typography>
                        </div>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    )
}

export default UnAuthNav;