import React, { useEffect, useState } from 'react';
import styles from './SidenavRight.module.css';
import { ReactComponent as SearchIcon } from '../../../assets/DashboardIcons/searchicon.svg';
import { ReactComponent as FilterIcon } from '../../../assets/DashboardIcons/FilterIcon.svg';
import { ReactComponent as DropDownIcon } from '../../../assets/DashboardIcons/DropDownIcon.svg';
import { ReactComponent as DropUpIcon } from '../../../assets/DashboardIcons/DropUpIcon.svg';
import { Link } from 'react-router-dom';

const SidenavRight = ({ studentsList, setStudentsList = () => {} }) => {
  console.log(studentsList, " inside sideright");
  const [isSubDropDownClick1, setIsSubDropDownClick1] = useState(false);
  const [isSubDropDownClick2, setIsSubDropDownClick2] = useState(false);
  const [isSubDropDownClick3, setIsSubDropDownClick3] = useState(false);

  // handle 'Clear All' onClick
  /* const [isClearAllClicked, setIsClearAllClicked] = useState(false);
  const handleClearAll = () => {

  } */

  /* Filters */
  const tags = [];
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [isFilterDisplay, setIsFilterDisplay] = useState(false);
  // console.log(studentsList, "Hello there");
  const handleCheckboxClick = (e) => {
    const { name, value, checked } = e.target;
    // console.log(name, value, checked);
    if (checked) {
      setSelectedItems(prevSelectedItems => [...prevSelectedItems, { parameter: name, value: value }]);
    } else {
      setSelectedItems(prevSelectedItems => prevSelectedItems.filter(item => item.value !== value));
    }
  }
  console.log(selectedItems);
  const handleApplyFilter = () => {
    console.log("Apply Clicked ", selectedItems);
    console.log("studentList ", studentsList);
    setIsFilterDisplay(prev => !prev);
    /*
      const filtered = students.filter(stud => {
      return tags.some(tag => {
          const studentValue = stud[tag.parameter];
          if (!studentValue) return false;
          return studentValue.includes(tag.value);
        });
      }); 
    */
    const selectedValues = selectedItems.map(item => item.value);
    if(searchTerm.length) selectedValues.push(searchTerm.toLowerCase());
    const filtered = studentsList.filter(stud => {
      return selectedValues.every(selectedValue => Object.values(stud).some(para => selectedValue.toLowerCase().includes(String(para).toLowerCase())));
    });
    console.log(filtered, " filtered students");
    let filteredSorted = filtered.sort((a, b) => {
      let x = a.name.toLowerCase(), y = b.name.toLowerCase();
      if(x > y) return 1;
      else if(x < y) return -1;
      return 0;
    });
    setFilteredStudents(filteredSorted);
    setStudentsList(filteredSorted);
  }

  const handleClickSubDropdown1 = () => {
    setIsSubDropDownClick1(!isSubDropDownClick1);
  }
  const handleClickSubDropdown2 = () => {
    setIsSubDropDownClick2(!isSubDropDownClick2);
  }
  const handleClickSubDropdown3 = () => {
    setIsSubDropDownClick3(!isSubDropDownClick3);
  }
  const handleOnClearAllBtn = () => {
    setSelectedItems([]);
    setSearchTerm('');
    setStudentsList(studentsList);
    setFilteredStudents([]);
  }
  /* Search Filters */
  const handleSearchInputText = (txt) => {
    setSearchTerm(txt);
    // console.log(searchTerm);
  }
  const handleApplyFilterSearchTerm = () => {
    const filtered = studentsList.filter(stud => {
      return stud.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      stud.status.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setFilteredStudents(filtered);
  }
  useEffect(() => {
    if(searchTerm) handleApplyFilterSearchTerm();
  }, [searchTerm]);
  return (
    <div className={styles.sidenav}>
      <div className={styles.inputField}>
        <input onKeyDown={(e) => {if(e.key === 'Enter') { setStudentsList(filteredStudents);}}} type="text" placeholder='Search' value={searchTerm} onChange={(e) => handleSearchInputText(e.target.value)} />
        <SearchIcon className={styles.searchIcon} onClick={() => { setStudentsList(filteredStudents); }} />
        <FilterIcon className={styles.filterIcon} onClick={() => setIsFilterDisplay(!isFilterDisplay)} />
      </div>

      {
        isFilterDisplay ?
          (
            <div className={styles.dropDown}>
              <div className={styles.subDropdown} onClick={handleClickSubDropdown1}>
                <p className={styles.sidenavRightP}>Class</p>
                {isSubDropDownClick1 ? <DropUpIcon /> : <DropDownIcon />}
              </div>
              {/* <hr style={{border: 'none', borderTop: '1px solid #3FCBFF', margin: '10px 0'}} /> */}
              {
                isSubDropDownClick1 && (
                  <div className={styles.dropDownMenuItems}>
                    <div>
                      <div>8th</div>
                      <div><input type="checkbox" name='class' value='8th' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'class' && selectedItem.value === '8th')} onChange={handleCheckboxClick} /></div>
                    </div>
                    <div>
                      <div>9th</div>
                      <div><input type="checkbox" name='class' value='9th' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'class' && selectedItem.value === '9th')} onChange={handleCheckboxClick} /></div>
                    </div>
                    <div>
                      <div>10th</div>
                      <div><input type="checkbox" name='class' value='10th' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'class' && selectedItem.value === '10th')} onChange={handleCheckboxClick} /></div>
                    </div>
                    <div>
                      <div>11th</div>
                      <div><input type="checkbox" name='class' value='11th' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'class' && selectedItem.value === '11th')} onChange={handleCheckboxClick} /></div>
                    </div>
                    <div>
                      <div>12th</div>
                      <div><input type="checkbox" name='class' value='12th' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'class' && selectedItem.value === '12th')} onChange={handleCheckboxClick} /></div>
                    </div>
                    <div>
                      <div>IIT JEE</div>
                      <div><input type="checkbox" name='iitjee' value='IIT JEE' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'iitjee' && selectedItem.value === 'IIT JEE')} onChange={handleCheckboxClick} /></div>
                    </div>
                    <div>
                      <div>Other Courses</div>
                      <div><input type="checkbox" name='others' value='Other Courses' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'others' && selectedItem.value === 'Other Courses')} onChange={handleCheckboxClick} /></div>
                    </div>
                  </div>
                )
              }
              <div className={styles.subDropdown} onClick={handleClickSubDropdown2}>
                <p className={styles.sidenavRightP}>Section</p>
                {isSubDropDownClick2 ? <DropUpIcon /> : <DropDownIcon />}
              </div>
              {
                isSubDropDownClick2 && (
                  <div className={styles.dropDownMenuItems}>
                    <div>
                      <div>A</div>
                      <div><input type="checkbox" name='section' value='A' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'section' && selectedItem.value === 'A')} onChange={handleCheckboxClick} /></div>
                    </div>
                    <div>
                      <div>B</div>
                      <div><input type="checkbox" name='section' value='B' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'section' && selectedItem.value === 'B')} onChange={handleCheckboxClick} /></div>
                    </div>
                    <div>
                      <div>C</div>
                      <div><input type="checkbox" name='section' value='C' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'section' && selectedItem.value === 'C')} onChange={handleCheckboxClick} /></div>
                    </div>
                  </div>
                )
              }
              <div className={styles.subDropdown} onClick={handleClickSubDropdown3}>
                <p className={styles.sidenavRightP}>Status</p>
                {isSubDropDownClick3 ? <DropUpIcon /> : <DropDownIcon />}
              </div>
              {
                isSubDropDownClick3 && (
                  <div className={styles.dropDownMenuItems}>
                    <div>
                      <div><span style={{ width: '0.513vw', height: '0.513vw', borderRadius: '4px', backgroundColor: '#E53D44', margin: '3.4px 4px 2.5px 0px' }}></span>Critical</div>
                      <div><input type="checkbox" name='status' value='Critical' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'status' && selectedItem.value === 'Critical')} onChange={handleCheckboxClick} /></div>
                    </div>
                    <div>
                      <div><span style={{ width: '0.513vw', height: '0.513vw', borderRadius: '4px', backgroundColor: '#133593', margin: '3.4px 4px 2.5px 0px' }}></span>Watchful</div>
                      <div><input type="checkbox" name='status' value='Watchful' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'status' && selectedItem.value === 'Watchful')} onChange={handleCheckboxClick} /></div>
                    </div>
                    <div>
                      <div><span style={{ width: '0.513vw', height: '0.513vw', borderRadius: '4px', backgroundColor: '#3C9313', margin: '3.4px 4px 2.5px 0px' }}></span>Outstanding</div>
                      <div><input type="checkbox" name='status' value='Outstanding' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'status' && selectedItem.value === 'Outstanding')} onChange={handleCheckboxClick} /></div>
                    </div>
                  </div>
                )
              }
              <div className={styles.btnsPlacing}>
                {/* <ButtonSm value='Clear all' border='1.14px solid #313C63' marginRight='8px' />
            <ButtonSm value='Apply' bgc='#313C63' color='white' /> */}
                <button className={styles.btn} style={{ border: '1.14px solid #313C63', marginRight: '0.513vw' }} onClick={() => {setSelectedItems([]); setSearchTerm('');}}>Clear all</button>
                <button className={styles.btn} style={{ backgroundColor: '#313C63', color: 'white', marginRight: '0.513vw' }} onClick={() => { handleApplyFilter() }}>Apply</button>
              </div>
            </div>
          ) :
          (
            <div className={styles.dropDownMenuItems1}>
              {
                filteredStudents.map((stud, idx) => (
                  <div style={{margin: '0 0.256vw'}} key={stud.user_id}>
                    {/* Here, Add student_id to get specific student profile page */}
                    <Link to={`/studentprofile/${stud.user_id}`} className={styles.filteredItemsStyle}>
                      <div>{stud.name}</div>
                      <div style={{ width: '0.513vw', height: '0.513vw', borderRadius: '4px', 
                        backgroundColor: stud.status === 'OutStanding' ? 'rgba(60, 147, 19, 1)' : stud.status === 'Watchful' ? 'rgba(19, 53, 147, 1)' : 'rgba(229, 61, 68, 1)', 
                        margin: '3.4px 4px 2.5px 0px' }}></div>
                    </Link>
                  </div>
                ))
              }
              <div className={styles.btnsPlacing}>
                {/* <ButtonSm value='Clear all' border='1.14px solid #313C63' marginRight='8px' />
            <ButtonSm value='Apply' bgc='#313C63' color='white' /> */}
                <button className={styles.btn} style={{ border: '1.14px solid #313C63', marginRight: '0.513vw' }} onClick={() => handleOnClearAllBtn()}>Clear all</button>
                {/* <button className={styles.btn} style={{ backgroundColor: '#313C63', color: 'white', marginRight: '8px' }} onClick={() => {handleApplyFilter()}}>Apply</button> */}
              </div>
            </div>
          )
      }
    </div>
  )
}

export default SidenavRight