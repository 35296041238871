import React, { useEffect, useRef, useState } from 'react'
import Wrapper from '../WrapperDesktop/Wrapper'
import VerificationDialogTeacher from '../Common/Verification/VerificationDialogTeacher';
import { Dialog, DialogTitle, FormControl, InputLabel, OutlinedInput, Button, InputAdornment, IconButton,  } from '@mui/material';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import styles from './TeacherProfile.module.css'
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TeacherProfile = () => {
  const institute_id = localStorage.getItem('institute_id');
  const [isEdit, setIsEdit] = useState(false);
  const [temp2, setTemp2] = useState(null); /* Details of teachers is saved in this variable */

  /* For Reset Dialog */
  const [showPassword, setShowPassword] = useState(false);
  const [resetDialog, setResetDialog] = useState(false);
  const [resetPassword, setResetPassword] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  const handleCloseDialog = () => {
    setResetDialog(false);
    setResetPassword({
      oldPassword: "",
      password: "",
      confirmPassword: "",
    })
  };
  const handleResetFormChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, " got the value");
    setResetPassword((prevValues) => ({ ...prevValues, [name]: value }));
  };

  /* Profile Upload */
  const [profileImage, setProfileImage] = useState();
  const fileInputRef = useRef(null);

  /* OTP Verification */
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [isValidationCorrect, setIsValidationCorrect] = useState(false);

  /* Teacher Details Update */
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [mobile, setMobile] = useState('');
  const [state, setState] = useState('');
  const [institution, setInstitution] = useState('');
  const [city, setCity] = useState('');
  const [username, setUsername] = useState('');
  const [pincode, setPincode] = useState('');
  const [profileUrl, setProfileUrl] = useState('');
  const [institute_referral_id, setInstituteReferralId] = useState('');
  const updatedDetails1 = {
    email,
    country,
    mobile,
    state,
    institution,
    city,
    username,
    pincode
  };

  const setValuesFromProp = () => {
    setEmail(temp2?.institute_email);
    setCountry(temp2?.country);
    setMobile(temp2?.institute_mobile_no);
    setState(temp2?.institute_state);
    setInstitution(temp2?.institute_type);
    setCity(temp2?.institute_city);
    setUsername(temp2?.institute_name);
    setPincode(temp2?.institute_pincode);
    setProfileUrl(temp2?.profile_photo_url);
    setInstituteReferralId(temp2?.institute_referral_id);
  }


  useEffect(() => {
    // console.log(temp2);
    if (temp2) {
      setValuesFromProp()
      console.log(temp2);
    }
  }, [temp2])
  /* Getting Teacher Details */
  // temp2 holds the current logged-in Teacher Details

  /* Handling S3-bucket Profile-Photo upload */
  useEffect(() => {
    const handleS3ImageUpload = async () => {
      let imageUrlS3;
      try {
        const formData = new FormData();
        formData.append("file", profileImage);
        const resProfilePhotoS3Link = await axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/teacherprofileupload/${institute_id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            },
          }
        );
        imageUrlS3 = resProfilePhotoS3Link.data;
        setProfileUrl(imageUrlS3);
        console.log(imageUrlS3);
      } catch (error) {
        console.log("Error uploading file:", error);
      }
    }
    handleS3ImageUpload();
  }, [profileImage]);

  const handleSubmit = async (event) => {
    // event.preventDefault();
    // check if all the fields are filled and other conditions
    if (email && country && mobile && state && institution && city && username && pincode) {
      if(mobile.length !== 10){
        toast.error('Mobile number must be 10 digits long!');
        return;
      } else if(pincode.length !== 6){
        toast.error('Pincode must be 6 digits long!');
        return;
      }
      setOpenVerificationDialog(true);
    }
    else {
      toast.error('All fields are required!');
    }
  }

  const validateCheck = async () => {
    if (isValidationCorrect) {
      setOpenVerificationDialog(false);
      // console.log(event.target);
      console.log(updatedDetails1, openVerificationDialog, " got the edited details");
      const updatedDetails = {
        user_id: 1,
        email,
        country,
        mobile,
        state,
        institution,
        city,
        username,
        pincode,
        profile_photo_url: profileUrl
      };
      try {
        console.log(updatedDetails, " fuck u");
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/teacherprofileedit/${institute_id}`, updatedDetails);
        if(res.status === 200){
          setIsEdit(false);
          toast.success('Institute Details Updated Successfully!');
        }
      } catch (error) {
        console.log(error, {error: 'Something went wrong while updating data!'});
      }
    }else{
      toast.dismiss('Something went wrong!, while validating your OTP');
    }
  }

  useEffect(()=>{
    validateCheck();
  },[isValidationCorrect])
  const handlePasswordChange = async () => {
    const { oldPassword, password, confirmPassword } = resetPassword;
    if(!oldPassword || !password || !confirmPassword){
      toast.error('All fields are required!');
      return;
    } 
    else if(password !== confirmPassword){
      toast.error('Passwords do not match!');
      return;
    }
    // check if oldPassword is correct
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/change-password/password/${institute_id}`, {password: oldPassword});
      if(res.status === 200){
        // if correct then change the password with newPassword
        const res1 = await axios.put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/change-password/reset-password/${institute_id}`, {newPassword: password});
        console.log(res1.data, res1.status,  " got the response");
        if(res1.status === 200){
          toast.success('Password Changed Successfully!');
          setResetDialog(false);
          setResetPassword({
            oldPassword: "",
            password: "",
            confirmPassword: "",
          });
        }
      }
    } catch (error) {
      console.log("wrong old password");
      toast.error('Old Password is incorrect!');
    }
  }

  const handleClick = () => {
    setValuesFromProp()
    setIsEdit(false)
  }

  /* Profile Photo Handle */
  const handleFileChange = async (event) => {
    console.log("clicked");
    const file = event.target.files[0];
    console.log(file, " file got");
    setProfileImage(file);
    console.log(profileImage, " profile Image got");
  };
  const handleProfileUpload = () => {
    fileInputRef.current.click();
    console.log("clicked");
  }
  return (
    <>
      <Wrapper setTemp2={setTemp2} />
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        margin: '2.564vw 19.231vw 1vw 8.462vw',
        alignItems: 'center',
        fontSize: '1.538vw',
        fontWeight: '500'
      }}>
        {isEdit ? 'Edit Profile' : 'Profile'}
      </div>
      <ToastContainer />
      <div className={styles.container}>
        {
          profileUrl == null ?
            (
              <svg width="10.256vw" height="10.256vw" viewBox="0 0 179 179" fill="none" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <filter id="brightnessContrast">
                    <feComponentTransfer>
                      <feFuncR type="linear" slope="1.5" intercept="0" />
                      <feFuncG type="linear" slope="1.5" intercept="0" />
                      <feFuncB type="linear" slope="1.5" intercept="0" />
                    </feComponentTransfer>
                  </filter>
                </defs>
                <g clip-path="url(#clip0_230_303)" filter="url(#brightnessContrast)">
                  <path d="M150.344 9H27.656C17.3526 9 9 17.3526 9 27.656V150.344C9 160.647 17.3526 169 27.656 169H150.344C160.647 169 169 160.647 169 150.344V27.656C169 17.3526 160.647 9 150.344 9Z" fill="#313C61" />
                  <mask id="mask0_230_303" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="9" y="9" width="160" height="160">
                    <path d="M150.344 9H27.656C17.3526 9 9 17.3526 9 27.656V150.344C9 160.647 17.3526 169 27.656 169H150.344C160.647 169 169 160.647 169 150.344V27.656C169 17.3526 160.647 9 150.344 9Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_230_303)">
                    <path d="M108.112 87.792H102.848C98.5382 90.031 93.7528 91.1999 88.8961 91.1999C84.0393 91.1999 79.2539 90.031 74.9441 87.792H69.6881C63.4854 87.7983 57.5384 90.2646 53.1516 94.6499C48.7649 99.0351 46.2966 104.981 46.2881 111.184V141.512H131.504V111.184C131.496 104.983 129.028 99.0377 124.643 94.6527C120.258 90.2677 114.313 87.8005 108.112 87.792Z" fill="white" />
                    <path d="M113.496 60.792C113.496 55.9266 112.053 51.1705 109.35 47.125C106.647 43.0796 102.805 39.9265 98.3099 38.0646C93.8149 36.2027 88.8686 35.7155 84.0967 36.6647C79.3248 37.6139 74.9415 39.9568 71.5011 43.3972C68.0607 46.8376 65.7178 51.2209 64.7686 55.9928C63.8194 60.7647 64.3066 65.711 66.1685 70.206C68.0304 74.7011 71.1834 78.5431 75.2289 81.2462C79.2743 83.9493 84.0305 85.392 88.8959 85.392C95.4183 85.3857 101.672 82.7919 106.284 78.1798C110.896 73.5678 113.49 67.3144 113.496 60.792Z" fill="white" />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_230_303">
                    <rect width="160" height="160" fill="white" transform="translate(9 9)" />
                  </clipPath>
                </defs>
              </svg>
            ) :
            (
              <img src={profileUrl} alt="" style={{
                width: '10.256vw', height: '10.256vw', borderRadius: '16px', objectFit: 'cover', boxShadow: '3.13px 3.13px 6.25px 0px rgba(89, 89, 111, 0.5), -2.08px -2.08px 6.25px 0px rgba(255, 255, 255, 1)'
              }} />
            )
        }

        {/* Profile Upload */}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <span style={{ fontSize: '12px', cursor: 'pointer', color: 'rgba(22, 22, 255, 0.914)' }} onClick={handleProfileUpload}>{isEdit && 'change'}</span>
        <div style={{ fontSize: '1.282vw', fontWeight: '700', margin: '1vw auto 0.4vw auto' }}>{username}</div>
        <div style={{marginBottom: '10px', fontSize: '0.75vw', fontWeight: '600'}}>Ref. ID: {institute_referral_id}</div>
        {openVerificationDialog &&
          <div style={{ zIndex: '8' }}>
            <VerificationDialogTeacher email={email} name={username} userData={updatedDetails1} setOpenVerificationDialog={setOpenVerificationDialog} setIsValidationCorrect={setIsValidationCorrect} />
          </div>
        }
        <form className={styles.detailsGrid}>
          <div>
            <label htmlFor="">Email:</label>
            <input type="email" placeholder='Email' value={email} onChange={(e) => isEdit && setEmail(e.target.value)} />
          </div>
          <div>
            <label htmlFor="">Country:</label>
            <input type="text" placeholder='Country' value={country} onChange={(e) => isEdit && setCountry(e.target.value)} />
          </div>
          <div>
            <label htmlFor="">Mobile:</label>
            <input type="phone" placeholder='Mobile' value={mobile} onChange={(e) => isEdit && setMobile(e.target.value)} />
          </div>
          <div>
            <label htmlFor="">State:</label>
            <input type="text" placeholder='State' value={state} onChange={(e) => isEdit && setState(e.target.value)} />
          </div>
          <div>
            <label htmlFor="">Institution type:</label>
            <input type="text" placeholder='Institution' value={institution} onChange={(e) => isEdit && setInstitution(e.target.value)} />
          </div>
          <div>
            <label htmlFor="">City:</label>
            <input type="text" placeholder='City' value={city} onChange={(e) => isEdit && setCity(e.target.value)} />
          </div>
          <div>
            <label htmlFor="">Instiute Name:</label>
            <input type="text" placeholder='Institute Username/Username' value={username} onChange={(e) => isEdit && setUsername(e.target.value)} />
          </div>
          <div>
            <label htmlFor="">Pin code:</label>
            <input type="text" placeholder='Pin code' value={pincode} onChange={(e) => isEdit && setPincode(e.target.value)} />
            <span className={styles.changePasswordSpan} onClick={() => setResetDialog(true)}>{isEdit && 'Change Password'}</span>
          </div>
          {/* <div style={{display: isEdit && 'none'}}>
            <label htmlFor="">Institute Referral ID:</label>
            <input type="text" placeholder='Institute Referral ID' value={institute_referral_id} />
          </div> */}
        </form>
        {
            isEdit && (
              <div className={styles.onEdit}>
                <button className="outlinedBtnBig" onClick={() => handleClick()}>Cancel</button>
                <button className="filledBtnBig" onClick={() => handleSubmit()}>Save</button>
              </div>
            )
        }
        {/* Reset Employee Password */}
        <Dialog open={resetDialog} onClose={handleCloseDialog}>
          {/* <DialogTitle style={{ fontWeight: '500' }}>Reset Employee Password</DialogTitle> */}
          <form style={{margin: '2.051vw'}}>
            <FormControl variant="outlined" style={{ width: '100%', marginBottom: '1.538vw' }} sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                  boxShadow: 'none',
                },
              },
              boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
            }}>

              <InputLabel htmlFor="outlined-adornment-password" sx={{ "&.Mui-focused": { color: "#A8A8A8" } }}>
                Enter Your Old Password
              </InputLabel>
              <OutlinedInput
                fullWidth={true}
                name="oldPassword"
                onChange={handleResetFormChange}
                sx={{ backgroundColor: "transparent" }}
                id="outlined-adornment-password1"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      style={{ background: "none" }}
                      onClick={() => {
                        setShowPassword((prev) => !prev);
                      }}
                      onMouseDown={(e) => {
                        e.preventDefault();
                      }}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>

            <FormControl variant="outlined" style={{ width: '100%', marginBottom: '1.538vw' }} sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                  boxShadow: 'none',
                },
              },
              boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
            }}>

              <InputLabel htmlFor="outlined-adornment-password" sx={{ "&.Mui-focused": { color: "#A8A8A8" } }}>
                New Password
              </InputLabel>
              <OutlinedInput
                fullWidth={true}
                name="password"
                onChange={handleResetFormChange}
                sx={{ backgroundColor: "transparent" }}
                id="outlined-adornment-password2"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      style={{ background: "none" }}
                      onClick={() => {
                        setShowPassword((prev) => !prev);
                      }}
                      onMouseDown={(e) => {
                        e.preventDefault();
                      }}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            
            <FormControl variant="outlined" style={{ width: '100%', marginBottom: '1.538vw' }} sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                  boxShadow: 'none',
                },
              },
              boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
            }}>
              <InputLabel htmlFor="outlined-adornment-password" sx={{ "&.Mui-focused": { color: "#A8A8A8" } }}>
                Confirm Your Password
              </InputLabel>
              <OutlinedInput
                fullWidth={true}
                name="confirmPassword"
                onChange={handleResetFormChange}
                sx={{ backgroundColor: "transparent" }}
                id="outlined-adornment-password3"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      style={{ background: "none" }}
                      onClick={() => {
                        setShowPassword((prev) => !prev);
                      }}
                      onMouseDown={(e) => {
                        e.preventDefault();
                      }}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
            </FormControl>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setResetDialog(false)}
                className="mt-2"
                // disabled={!resetPassword.confirmPassword || resetPassword.confirmPassword !== resetPassword.password}
                style={{ flex: '1', height: '2.564vw', background: 'none', border: '2.03px solid #37446F', color: 'black', marginRight: '0.513vw', textTransform: 'none' }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handlePasswordChange()}
                className="mt-2"
                // disabled={!resetPassword.oldPassword || !resetPassword.confirmPassword || resetPassword.confirmPassword !== resetPassword.password}
                style={{ flex: '1', background: '#37446F', height: '2.564vw', color: 'white', textTransform: 'none' }}
              >
                Save
              </Button>
            </div>
          </form>
        </Dialog>
        <ToastContainer />
        {
          !isEdit && (
            <button className='filledBtnBig' onClick={() => setIsEdit(!isEdit)}>Edit Profile</button>
          )
        }
      </div>
    </>
  )
}

export default TeacherProfile

/* Teacher ProfileIcon Page and Edit page both in same component */