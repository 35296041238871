import React, { useEffect, useState } from 'react'
import Navbar from '../Common/NavBars/Navbar'
import SidenavLeft from '../Common/NavBars/SidenavLeft'

const Wrapper = ({setTemp2=()=>{}, isActivePage}) => {
  const [temp,setTemp]=useState({});
  
  useEffect(()=>{
    setTemp2(temp);
  },[temp]);
  return (
    <>
      <Navbar setTemp={setTemp}/>
      <SidenavLeft isActivePage={isActivePage} />
    </>
  )
}

export default Wrapper