import React from "react";
import styles from "./ForgotPassword.module.css";
import { Container } from "@mui/system";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
    Dialog,
    DialogTitle,
    Grid,
    DialogContent,
    DialogActions,
    Button,
    TextField,
} from "@mui/material";
import ChangePasswordDesktop from "../ChangePassword/ChangePassword";

const ForgotPasswordDesktop = () => {
    const [open, setOpen] = useState(false);
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [mail, setMail] = useState();
    const [message, setMessage] = useState();
    const [otpCode, setOtpCode] = useState("");
    const [verificationMessage, setVerificationMessage] = useState("");
    const [instituteId, setInstituteId] = useState();
    const navigate = useNavigate();

    const generateOtp = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/change-password/forgot-password-otp`,
                {
                    mail: mail,
                }
            );
            // console.log(response.data);

                setMessage(response.data.message);
                setInstituteId(response.data.instituteId);
                // console.log(response.data.instituteId);
                // console.log(response.data);
                setMail("");
                setOpen(true);
        } catch (error) {
            const errorMessage = error.response?.data?.message || "An error occurred";
            console.error("Error generating OTP:", error);
            setMessage(errorMessage);
        }
    };

    const handleValidateOTP = () => {
        if (otpCode.length !== 6) {
            setVerificationMessage("OTP must be 6 digits long");
            return;
        }
        // console.log("Institute ID before validation:", instituteId);
        axios
            .put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/change-password/validate-otp/${instituteId}`, {
                otp: otpCode,
            })
            .then((response) => {
                console.log("OTP validation successful:", response.data);
                setVerificationMessage(response.data.message);
                setShowResetPassword(true);
            })
            .catch((error) => {
                const errorMessage = error.response?.data?.message || "An error occurred during OTP validation";
                console.error("Error validating OTP:", errorMessage);
                setVerificationMessage(errorMessage);
            });
    };

    const handleClose = () => {
        setOpen(false);
        navigate(`/login`);
    };

    return (
        <>
            {!open ? (
                <div className={styles.passwordContainer}>
                    <div className={styles.pageHeader}>Forgot Password</div>
                    <Grid>
                        <TextField
                            type="mail"
                            label="Enter Institute Email"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => setMail(e.target.value)}
                            style={{width: '32vw'}}
                        />
                    </Grid>
                    <Button className={styles.resetButton} onClick={generateOtp}>
                        SEND OTP
                    </Button>
                    <div style={{ font: "0.8125vw", marginTop: "1.5vw", color: "red" }}>{message}</div>
                </div>
            ) : (
                <>
                    {showResetPassword ? (
                        <ChangePasswordDesktop instituteId={instituteId} page='forgot' />
                    ) : (
                        <div>
                            <Dialog open={open} onClose={handleClose} sx={{ '& .MuiPaper-root': { }, }}>
                                {/* <div className={styles.verifyMessage}>
                                    {message}
                                </div> */}
                                <DialogTitle>Verify Your Mail</DialogTitle>
                                <DialogContent>
                                    <TextField
                                        className={styles.verifyField}
                                        autoFocus
                                        margin="dense"
                                        id="otp"
                                        label="Enter OTP Code"
                                        type="text"
                                        fullWidth
                                        value={otpCode}
                                        onChange={(e) => setOtpCode(e.target.value)}
                                    />
                                    <div style={{ color: "#cccccc" }}>{verificationMessage}</div>
                                </DialogContent>
                                <DialogActions className={styles.buttonContainer}>
                                    <Button onClick={handleClose} className={styles.canButton}>
                                        Cancel
                                    </Button>
                                    <Button onClick={handleValidateOTP} className={styles.validateButton}>
                                        Confirm
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default ForgotPasswordDesktop;
