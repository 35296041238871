import React, { useEffect, useState } from 'react';
import styles from './TimelineSidenavRight.module.css';

/* SearchIcon, FilterIcon, DropDownIcon */
// import { ReactComponent as SearchIcon } from '../../assets/DashboardIcons/searchIcon.svg';
import { ReactComponent as FilterIcon } from '../../assets/DashboardIcons/FilterIcon.svg';
import { ReactComponent as DropDownIcon } from '../../assets/DashboardIcons/DropDownIcon.svg';
import { ReactComponent as DropUpIcon } from '../../assets/DashboardIcons/DropUpIcon.svg';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const SidenavRight = ({ setTimelineCustomDate = () => {}, setTrackNavbarRight = () => {}, setTrackNavbarFilter = () => {} }) => {
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const [isSubDropDownClick1, setIsSubDropDownClick1] = useState(false);
  const [isSubDropDownClick2, setIsSubDropDownClick2] = useState(false);
  const [isSubDropDownClick3, setIsSubDropDownClick3] = useState(false);
  const [current, setCurrent] = useState({isActive: true, index: -1});

  /* Filter Variables */
  const [customDate, setCustomDate] = useState([]);
  const [last4Months, setLast4Months] = useState([]);
  const [last4Weeks, setLast4Weeks] = useState([]);


  const handleClickSubDropdown1 = () => {
    setIsSubDropDownClick1(!isSubDropDownClick1);
  }
  const handleClickSubDropdown2 = () => {
    setIsSubDropDownClick2(!isSubDropDownClick2);
  }
  const handleClickSubDropdown3 = () => {
    setIsSubDropDownClick3(!isSubDropDownClick3);
  }

  /* Filters */
  // let { user_id } = useParams();
  // // console.log(instituteID, " instit ka ID");
  // // const effectiveID = user_id === undefined ? instituteID : user_id;
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const resCustomDate = await axios.get(`${process.env.REACT_APP_API_URL}/timelinefilter/${user_id}`);
  //     console.log(resCustomDate.data, " db aaya");
  //     setTimelineCustomDate(resCustomDate.data);
  //     } catch (error) {
  //       console.log("Error in timeline filter",error);
  //     }
      
  //   }
  //   if(user_id !== undefined) fetchData();
  // }, [user_id]);
  const handleFilterCustomDate = (e) => {
    const { name, value } = e.target;
    setCustomDate((prev) => ({
      ...prev,
      [name]: value
    }));
  }
  const handleFilterCustomWeekAndMonth = (e, idx) => {
    const name = e.currentTarget.getAttribute('name');
    const value = e.currentTarget.getAttribute('value');
    // console.log(name, value, " getting date other than custom");
    if(name === 'month'){
      setCurrent(prev => ({...prev, index: idx + 4}));
      console.log(value, " got month");
      const date = new Date();
      console.log(date.getFullYear(), " full year");
      const yr = date.getFullYear();
      const mth = String(months.indexOf(value) + 1).padStart(2, '0');
      // 2024-08-01 2024-08-12  month date got

      /* to calculate last date of any month */
      const lastday = (y, m) => {
        return new Date(y, m + 1, 0).getDate();
      }
      let dy = '', currMonthIdx = date.getMonth(), givenMonthIdx = months.indexOf(value);
      if(givenMonthIdx === currMonthIdx)
        dy = String(date.getDate()).padStart(2, '0');
      else dy = String(lastday(yr, givenMonthIdx));
      console.log(yr, mth, dy, givenMonthIdx, currMonthIdx, " got the details date");
      const fromDate = `${yr}-${mth}-01`;
      const toDate = `${yr}-${mth}-${dy}`;
      console.log(fromDate, toDate, ' month date got');
      // setCustomDate({fromDate: fromDate, toDate: toDate});
      setTrackNavbarFilter({ event: 'apply', date: {fromDate: fromDate, toDate: toDate} });
      setTrackNavbarRight({ event: 'apply', date: {fromDate: fromDate, toDate: toDate} })
    }else if(name === 'weekrange'){
      setCurrent(prev => ({...prev, index: idx}));
      // weekrange 2024-07-11 - 2024-07-17
      const temp = value?.split(' - ');
      const fromDate = temp[0];
      const toDate = temp[1];
      console.log(fromDate, toDate, " weekrange date");
      // setCustomDate({fromDate: fromDate, toDate: toDate});
      setTrackNavbarFilter({ event: 'apply', date: {fromDate: fromDate, toDate: toDate} });
      setTrackNavbarRight({ event: 'apply', date: {fromDate: fromDate, toDate: toDate} })
    }
  }
  // console.log(customDate, " getting custome date");
  /* Last Four Weeks Calculations */
  const HandleLastFourWeeks = () => {
    const formatWeek = (start, end) => {
      const startString = `${start.getFullYear()}-${String(start.getMonth() + 1).padStart(2, '0')}-${String(start.getDate()).padStart(2, '0')}`;
      const endString = `${end.getFullYear()}-${String(end.getMonth() + 1).padStart(2, '0')}-${String(end.getDate()).padStart(2, '0')}`;
      return `${startString} - ${endString}`;
    };
    const today = new Date();
    const weeks = [];
    for (let i = 0; i < 4; i++) {
      const endOfWeek = new Date(today);
      endOfWeek.setDate(today.getDate() - (7 * i));
      const startOfWeek = new Date(endOfWeek);
      startOfWeek.setDate(endOfWeek.getDate() - 6);
      weeks.unshift(formatWeek(startOfWeek, endOfWeek));
    }
    // let result = [];
    // weeks.map((ele, index) => {
    //   if (index == 3) {
    //     result.unshift('This week');
    //     // console.log('This week');
    //   } else {
    //     const day1 = ele.substring(8, 10), day2 = ele.substring(21);
    //     const month1 = months[Number(ele.substring(5, 7)) - 1], month2 = months[Number(ele.substring(18, 20)) - 1];
    //     // console.log(`${day1} ${month1} - ${day2} ${month2}`);
    //     result.unshift(`${day1} ${month1} - ${day2} ${month2}`);
    //   }
    // });
    weeks.reverse();
    return weeks;
  };
  /* Last Four Months Calculations */
  
  const handleLastFourMonths = () => {
    const currentMonthIndex = new Date().getMonth();
    const lastFourMonths = [];
    for (let i = 0; i < 4; ++i) {
      const monthIndex = (currentMonthIndex - i + 12) % 12;
      lastFourMonths.unshift(months[monthIndex]);
    }
    lastFourMonths.reverse();
    return lastFourMonths;
  }
  useEffect(() => {
    setLast4Months(handleLastFourMonths());
    setLast4Weeks(HandleLastFourWeeks());
  }, []);

  return (
    <div className={styles.sidenav}>
      <div className={styles.dropDown}>
        <div className={styles.subDropdown} onClick={handleClickSubDropdown1}>
          <p className={styles.timelineSideNavRightP}>Weeks</p>
          {isSubDropDownClick1 ? <DropUpIcon /> : <DropDownIcon />}
        </div>
        {/* <hr style={{border: 'none', borderTop: '1px solid #3FCBFF', margin: '10px 0'}} /> */}
        {
          isSubDropDownClick1 && (
            <div className={styles.dropDownMenuItems}>
              {
                last4Weeks.map((weekRange, index) => (
                  <div style={{padding: '1vw', height: '2.564vw', borderBottom: current.index == index && current.isActive && '2px solid rgb(49, 60, 99)'}} key={index} name='weekrange' value={weekRange} onClick={(e) => handleFilterCustomWeekAndMonth(e, index)}>
                    {index === 0 ? 
                    ('This week') 
                    : 
                    (`${weekRange.substring(8, 10)} ${months[Number(weekRange.substring(5, 7)) - 1]} - 
                      ${weekRange.substring(21)} ${months[Number(weekRange.substring(18, 20)) - 1]}`)
                    }
                  </div>
                ))
              }
              {/* <div>This Week</div>
              <div>09 June - 15 June</div>
              <div>02 June - 08 June</div>
              <div>26 May - 01 June</div> */}
            </div>
          )
        }
        <div className={styles.subDropdown} onClick={handleClickSubDropdown2}>
          <p className={styles.timelineSideNavRightP}>Month</p>
          {isSubDropDownClick2 ? <DropUpIcon /> : <DropDownIcon />}
        </div>
        {
          isSubDropDownClick2 && (
            <div className={styles.dropDownMenuItems}>
              {
                last4Months.map((month, index) => (
                  <div style={{borderBottom: (current.index == index + 4 && current.isActive && '2px solid rgb(49, 60, 99)')}} key={index} name='month' value={month} onClick={(e) => handleFilterCustomWeekAndMonth(e, index)}>{month}</div>
                ))
              }
              {/* <div>June</div>
              <div>May</div>
              <div>April</div>
              <div>March</div> */}
            </div>
          )
        }
        {/* <div className={styles.subDropdown} onClick={handleClickSubDropdown3}>
          <p className={styles.timelineSideNavRightP}>Custom</p>
          {isSubDropDownClick3 ? <DropUpIcon /> : <DropDownIcon />}
        </div> */}
        {
          !isSubDropDownClick3 && (
            <>
              {/* <div className={styles.dropDownMenuItems1}>
                <div>From:</div>
                <div>To:</div>
                <input type="date" name="fromDate" id="" placeholder='From:' onChange={(e) => handleFilterCustomDate(e)} />
                <input type="date" name="toDate" id="" placeholder='To:' onChange={(e) => handleFilterCustomDate(e)} />
              </div> */}
              <div className={styles.btnsPlacing}>
                <button className={styles.btn} style={{ backgroundColor: '#313C63', color: 'white', marginRight: '8px', fontSize: '0.75rem' }} onClick={() => {
                  setTrackNavbarFilter([]);
                  setTrackNavbarRight([]);
                  setCurrent(prev => ({...prev, index: -1}));
                  }}>Clear</button>
                {/* <button className={styles.btn} style={{ backgroundColor: '#313C63', color: 'white', marginRight: '8px', fontSize: '0.75rem' }} onClick={() => {
                  setTrackNavbarRight({ event: 'apply', date: customDate });
                  setTrackNavbarFilter({event: 'apply', date: customDate });
                  }}>Apply</button> */}
                  
              </div>
            </>


          )
        }
      </div>
    </div>
  )
}

export default SidenavRight