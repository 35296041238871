import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import './App.css';
import {jwtDecode} from "jwt-decode"

import Dashboard from './components/Dashboard/Dashboard';
import TeacherProfile from './components/TeacherProfile/TeacherProfile';
import Timeline from './components/Timeline/Timeline';
import Home from './Home';
import StudentList from './components/StudentList/StudentList';
import AccountDetails from './components/AccountDetails/AccountDetails';
import Analytics from './components/Analytics/Analytics';
import Signup from './components/Auth/Signup/Signup';
import Login from './components/Auth/Login/Login'
import HelpPage from './components/HelpPage/HelpPage';
import HowItWorks from './components/HowItWorks/HowItWorks';
import AboutUs from './components/AboutUs/AboutUs';
import ForgotPasswordDesktop from './components/ForgotPassword/ForgotPassword';


const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("token");
  const decodedToken = token ? jwtDecode(token) : null;
  console.log(decodedToken);

  const isAuthenticated = token && decodedToken;
  const navigate = useNavigate();
  // console.log(hasLeveledDown);

  useEffect(() => {
    if (!isAuthenticated) {
      return navigate("/");
    }
  }, []);

  return (
    <>
      {children}
    </>
  );
};

const UnProtectedRoute=({children})=>{
  const token=localStorage.getItem("token")
  const decodedToken=token?jwtDecode(token):null;
  const navigate=useNavigate()
  console.log(token,decodedToken);
  
  useEffect(()=>{
    if (token && decodedToken) {
      return navigate("/dashboard");
    }
  },[])

  

  return (
    <>
      {children}
    </>
  );
}

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<UnProtectedRoute><Login /></UnProtectedRoute>} />
      {/* <Route path="/login" element={<Login />} /> */}
      <Route path='/timeline/:user_id' element={<ProtectedRoute><Timeline /></ProtectedRoute>} />
      <Route path='/teacherprofile' element={<ProtectedRoute><TeacherProfile /></ProtectedRoute>} />
      <Route path='/dashboard' element={<ProtectedRoute><StudentList /></ProtectedRoute>} /> {/* Student List */}
      <Route path='/accountdetails' element={<ProtectedRoute><AccountDetails /></ProtectedRoute>} />
      <Route path='/analytics' element={<ProtectedRoute><Analytics /></ProtectedRoute>} />
      <Route path="/studentprofile/:user_id" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
      {/* <Route path="/signup" element={<UnProtectedRoute><Signup /></UnProtectedRoute>} /> */}
      <Route path="/help" element={ <HelpPage />} />
      <Route path="/how-it-works" element={<ProtectedRoute><HowItWorks /></ProtectedRoute>} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/forgotPassword" element={<UnProtectedRoute><ForgotPasswordDesktop /></UnProtectedRoute>} />
    </Routes>
  )
}

export default App;