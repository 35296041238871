import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper} from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import isDesktop from '../../../utils/isDesktop';

const isDesktopTrue = isDesktop();
function SignupCarousel(props) {
    var items = [
        {
            name: "Efficient Teaching",
        },
        {
            name: "High Success Rate",
        },
        {
            name: "Business Growth",
        },
        {
            name: "Detailed Analysis",
        }
    ]   

    return (
        <Carousel
            NextIcon={<ArrowForwardIosIcon />}
            PrevIcon={<ArrowBackIosIcon />}
            animation='fade'
            indicators={false}
            navButtonsAlwaysVisible={true}
            navButtonsWrapperProps={{ style: { height: 'auto' } }}
            navButtonsProps={{
                style: {
                    borderRadius: 0,
                    backgroundColor: '#ffffff',
                    color: '#000000',
                    height: isDesktopTrue ? '8vw' : '4.615vw',
                    margin: '0'
                }
            }}
        >
            {
                items.map((item, i) => <Item key={i} item={item} />)
            }
        </Carousel>
    )
}
function Item(props) {
    return (
        <Paper sx={{
            height: isDesktopTrue ? '8vw' : '4.615vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#EAF1F7'
        }}
        >
            <p style={{ fontSize: isDesktopTrue ? '14px' : '1.026vw', fontWeight: '600' }}>{props.item.name}</p>
        </Paper>
    )
}

export default SignupCarousel;