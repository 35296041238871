import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CarouselImage1 from '../../../assets/carousel-images/carousel-image-1-Desktop.avif'
import CarouselImage2 from '../../../assets/carousel-images/carousel-image-2-Desktop.avif'
import CarouselImage3 from '../../../assets/carousel-images/carousel-image-3-Desktop.avif'
import CarouselImage4 from '../../../assets/carousel-images/carousel-image-4-Desktop.avif'


function ImageCarousel(props) {
    var items = [
        {
            header: "Real-time Analytics Dashboard",
            subtext: "Harness data-driven insights to enhance your teaching strategies and optimize student outcomes",
            image: CarouselImage1
        },
        {
            header: "Hawk-Eye View",
            subtext: "Student-wise daily action points for effectively monitoring and supporting student progress",
            image: CarouselImage2
        },
        {
            header: "Timeline",
            subtext: "Track and analyze students' performance over time to ensure consistent progress",
            image: CarouselImage3
        },
        {
            header: "Business/Earnings Summary",
            subtext: "Monitor business growth with in-depth insights through interactive charts",
            image: CarouselImage4
        }
    ];

    return (
        <Carousel
            animation="fade"
            sx={{overflow: 'visible', display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '25.25vw'}}
            navButtonsAlwaysInvisible={true}
        >
            {items.map((item, i) => (
                <Item key={i} item={item} />
            ))}
        </Carousel>
    );
}

function Item(props) {
    return (
        <Paper sx={{boxShadow:'none'}}>
            <img
                src={props.item.image}
                alt={props.item.header}
                style={{
                    width: '100%',
                    height: 'auto',
                    maxHeight: '30vw'
                }}
            />
            <div style={{ textAlign: 'center', padding: '1vw' }}>
                <h2 style={{ fontSize: '0.937vw', margin: '0', fontWeight:'600' }}>{props.item.header}</h2>
                <p style={{ fontSize: '0.793vw', margin: '0', fontWeight:'500' }}>{props.item.subtext}</p>
            </div>
        </Paper>
    );
}

export default ImageCarousel;
